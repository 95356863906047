import {useKeycloak} from "@react-keycloak/web";
import React, {useEffect} from "react";
import ProductsAvailableForPurchase from "./ProductsAvailableForPurchase";
import MemberCard from "./MemberCard"
import PurchasedProducts from "./PurchasedProducts";
import EfnLogo from "../logos/EfnLogo";

// when the token is available, and send it along to the graphql thingy.
const MembershipStatusPanel = () => {
    const {keycloak, initialized} = useKeycloak();

    if (!initialized) {
        return (
            <div><p>... loading</p></div>
        )
    }

    if (keycloak.authenticated) {
        return (<div>
            <MemberCard
                name={keycloak.tokenParsed.name}
                given_name={keycloak.tokenParsed.given_name}
                family_name={keycloak.tokenParsed.family_name}
                email={keycloak.tokenParsed.preferred_username}
                phone={keycloak.tokenParsed.phone}
            />
        </div>)
    } else {
        return (<div className="content-below">
            <p>For å registrere bruker og å betale for medlemsskap:</p>
            <ol>
                <li>Klikk på "Login".</li>
                <li>Registrer bruker (hvis du ikke allerede har gjort det).</li>
                <li>Du vil få se medlemskortet ditt.</li>
                <li>Hvis du ikke har betalt, kan du betale årsavgift.</li>
                <li>Hvis du har betalt vises det på medlemskortet.</li>
                <li>Uansett kan du danse en liten dans av glede ...</li>
                <li>... fordi du støtter <a href="https://www.efn.no/">EFN</a> ♥</li>
            </ol>
        </div>)
    }
    ;
}

export default MembershipStatusPanel;