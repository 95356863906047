import Keycloak from "keycloak-js";


// TODO: Do something like this: Get the variable bits from the enironment
//       so that it's easily ported to prod.
// const keycloak = new Keycloak({
//     realm: process.env.REACT_APP_KEYCLOAK_REALM,
//     url: process.env.REACT_APP_KEYCLOAK_URL,
//     clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID
// })


const initOptions = {
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
}
const keycloak = new Keycloak(initOptions);

export default keycloak;