import React from 'react';
import {gql, useLazyQuery} from '@apollo/client';
import VippsRgbBlackLogoHorizontal from "../logos/VippsRgbBlackLogoHorizontal";
import VippsRgbWhite from "../logos/VippsRgbWhite";

const BUY_QUERY = gql`
    query Buy($sku: String!) {
        buy(sku: $sku) {
            error,
            redirectUrl
        }
    }
`;

function BuyButton(props) {

    // Define the lazy query
    const [getBuyData, { loading, error, data }] =
        useLazyQuery(BUY_QUERY, {
            variables: { sku: props.sku },
        });

    // Handling the query response.  The idea is that initially there is no query,
    // so all of the data just falls through to the default at the end, which is to show the
    // button.  When the button is clicked, the lazy function is called, the redirect URL arrives
    // as a parameter, and at that point we we can do the redirect, which will initiate the
    // purchase action. Tallyho!
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :( </p>;
    if (data?.buy?.error) {
        console.log("Error detected" + data.buy.error)
        return <p>{data?.buy?.error}</p>
    }
    if (data?.buy?.redirectUrl) {
        window.location.href = data.buy.redirectUrl;
        return <p>Payment initiated</p>
    } else {
        return <button className={props.className} onClick={getBuyData}>{props.buttonText}</button>;
    }
}

export default BuyButton;