import React from "react";
import { useKeycloak } from "@react-keycloak/web";

const LoginOrLogoutButton = () => {
    const { keycloak, initialized } = useKeycloak();

    function loginButton() {
        return (
                <button
                    type="button"
                    className="login-button"
                    onClick={() => keycloak.login()}
                >
                    Login
                </button>
            );
    }

    function logoutButton() {
        return  (
            <button
                    type="button"
                    className="logout-button"
                    onClick={() => keycloak.logout()}
                >
                    Logout  ({keycloak.tokenParsed.preferred_username})
                </button>
        );
    }

    function loginOrLogoutButton() {
        if (keycloak.authenticated) {
            console.log("cka = True")
        } else {
            console.log("cka = False")
        }
                if (initialized) {
                    console.log("Is Initialized");
                    console.log("token parsed = ", keycloak.tokenParsed)
                    if (keycloak.authenticated) {
                        console.log("Keycloak  is authenticated= ", keycloak)
                        return logoutButton();
                    }
                    else {
                        return loginButton();
                    }
               } else {
                    console.log("Is not Initialized");
                    console.log("keycloak.authenticated = ", keycloak.authenticated)
                    console.log("Keycloak = ", keycloak)
                    return loginButton();
                }
    }

    return (<nav> {loginOrLogoutButton()} </nav>
    );
};

export default LoginOrLogoutButton;