import React from 'react';
import {useKeycloak} from "@react-keycloak/web";
import MembershipStatusPanel from "./MembershipStatusPanel";
import EfnLogo from "../logos/EfnLogo";
import './Homepage.css';
import LoginOrLogoutButton from "../components/LoginOrLogoutButton";

const Home = () => {

    return (
        <div className="toplevel">
            <EfnLogo className="toplevel-logo"/>
            <h1 className="efn-name-in-header">Elektronisk Forpost Norge</h1>
            <LoginOrLogoutButton/>
            <MembershipStatusPanel/>
        </div>
    );
};

export default Home;

