import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { setContext } from '@apollo/client/link/context';
import {useKeycloak} from "@react-keycloak/web";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    // TODO: Should I add this? CORS if f-ed up, and this one of the suggested fixes, but it doesn't work.
    // fetchOptions: {
    //     mode: 'no-cors'
    // },
});

const authLink = setContext((_, { headers }) => {

    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    const bearer_token =  token ? `Bearer ${token}` : "";
    const headers_to_use =  {
        headers: {
            ...headers,
            authorization: bearer_token,
        }
    };

    // TODO: localStorage doesn't transfer the token in the way I believe it should do, so
    //       the token isn't added to the headers, so things don't get authenticated.
    // return the headers to the context so httpLink can read them
    return  headers_to_use;
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode> // TODO: Re-enable this!!
    <ApolloProvider client={client}>
    <App />
    </ApolloProvider>,
  // </React.StrictMode>
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


