// src/ProductList.js
import React from 'react';
import {useQuery} from '@apollo/client';
import gql from 'graphql-tag';
import VippsBuy from "./BuyButton";
import keycloak from "../Keycloak";
import BuyButton from "./BuyButton";
import VippsRgbWhite from "../logos/VippsRgbWhite";
import VippsRgbBlackLogoHorizontal from "../logos/VippsRgbBlackLogoHorizontal";
import VippsLogoHorizontal from "../logos/VippsLogoHorizontal";

const GET_PRODUCTS = gql`
    {
        products {
            product {
                title
                sku
                displayPrice
            }
            quantity
        }
    }
`;


const ProductsAvailableForPurchase = (props) => {
    const {loading, error, data} = useQuery(GET_PRODUCTS,  {pollInterval: 500});

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const products = data.products;

    function getPl() {
        return <div>
            {products.map((item) => (
                <BuyButton className="membership-buy-button"
                           sku={item.product.sku}
                           buttonText={"Kjøp " + item.product.title + ". Pris: " + item.product.displayPrice}/>
            ))}
        </div>;
    }

    return (
        <div>

            <div>
                {products.length > 0 ? (
                    <div>
                        <h2>Tilgjengelige medlemskap</h2>
                        {getPl(products)}
                        <p>Betal med <VippsLogoHorizontal className="vipps-logo"/></p>
                    </div>
                ) : (
                    <div/>
                )}
            </div>
        </div>
    );
}


export default ProductsAvailableForPurchase;
