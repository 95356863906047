import React, {useEffect} from "react";
import {useKeycloak} from "@react-keycloak/web";
import ProductsAvailableForPurchase from "./ProductsAvailableForPurchase";
import PurchasedProducts from "./PurchasedProducts";
import './MemberCard.css';
import EfnLogo from "../logos/EfnLogo";

function MembershipStatusList(props) {
    const {keycloak, initialized} = useKeycloak();

    useEffect(() => {
        localStorage.setItem('token', keycloak.token);
    }, [keycloak.token]);

    if (!initialized) {
        return
    }
    return (<div>
        <ProductsAvailableForPurchase/>
        <PurchasedProducts/>
    </div>)
}

const MemberCard = (props) => {
    return (
        <div className="member-card">
            <div>
                <h1>Medlemskort</h1>
                <p><em>Navn:</em>  {props.name}</p>
                <p><em>Epost:</em> {props.email}</p>
                <MembershipStatusList userId={props.email}/>
            </div>
            <EfnLogo className="membership-card-efn-logo"/>
        </div>
    )
}

export default MemberCard;