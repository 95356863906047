import React from "react";

// Generated using
function EfnLogo(props) {
    return (
        <div className={props.className}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 224 224"
            version="1.1"
        >
            <g
                strokeDasharray="none"
                strokeMiterlimit="4"
                strokeOpacity="1"
                transform="translate(-.5 -827.862)"
            >
                <path
                    fill="#f7931d"
                    fillOpacity="1"
                    stroke="#202020"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="4.771"
                    d="M214.115 939.862a101.615 101.615 0 11-203.23 0 101.615 101.615 0 11203.23 0z"
                ></path>
                <g
                    fill="none"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="3"
                    transform="translate(.5 -.5)"
                >
                    <path
                        stroke="#202020"
                        d="M165 1025.862l-53-188 88.5 53.5-142.5 134.5v-172l155.5 86.5-190.5 48.5 89-151.5"
                    ></path>
                    <path
                        stroke="#000"
                        d="M200.5 988.862L23 891.362l89 150 53-187.5-154.5 86"
                    ></path>
                </g>
                <g
                    fill="#fff"
                    fillOpacity="1"
                    stroke="#202020"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="5"
                    transform="translate(.5 -.5)"
                >
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(6.286 70.898) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(6.286 273.898) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(-95.214 172.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(107.786 172.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(-47.714 86.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(-82.714 123.898) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(59.286 86.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(94.786 123.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(-82.714 221.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(-47.714 258.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(59.286 258.398) scale(.95238)"
                    ></path>
                    <path
                        d="M119 806.362a8 8 0 11-16 0 8 8 0 1116 0z"
                        transform="translate(94.786 221.398) scale(.95238)"
                    ></path>
                </g>
                <path
                    fill="#202020"
                    fillOpacity="1"
                    stroke="#fff"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth="3"
                    d="M30.5 905.362v69h71v-29.5h32v-12h-32v-16H142v57.5h13v-45.5l29 45.5h14.5v-69h-13v46.5l-29.5-46.5H87.5v57h-43v-17.5h34v-12H44v-16h38.5v-11.5z"
                ></path>
            </g>
        </svg>
        </div>
    );
}

export default EfnLogo;

