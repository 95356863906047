// src/ProductList.js
import React from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import VippsBuy from "./BuyButton";
import keycloak from "../Keycloak";
import BuyButton from "./BuyButton";

const GET_PURCHASED_PRODUCTS = gql`
    {
        inventory {
            product {
                title
            }
            quantity
        }
    }
`;


const  PurchasedProducts = (props) =>  {
    const { loading, error, data } = useQuery(GET_PURCHASED_PRODUCTS, {pollInterval: 500});

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    const products = data.inventory;

    function getPl() {
        return <ul>
            {products.map((item) => (
                <li key={item.product.sku}>
                    <strong>{item.product.title}</strong>
                </li>
            ))}
        </ul>;
    }

    return (
        <div>

            <div>
                {products.length > 0 ? (
                    <div>
                        <h2>Betalte medlemsskap</h2>
                        {getPl(products)}
                    </div>
                        ):<div/>}
                    </div>
                    </div>
                    );
                }


                export default PurchasedProducts;
