import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./Keycloak"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOrLogoutButton from "./components/LoginOrLogoutButton";
import Home from "./pages/Homepage";
import EfnLogo from "./logos/EfnLogo";


const keycloakProviderInitConfig = {
    onLoad: 'check-sso',
}

const onKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error)
}

const onKeycloakTokens = (tokens) => {
    console.log('onKeycloakTokens', tokens)
}

// TODO: Don't really know if this works or not.
keycloak.onTokenExpired = () => {
    console.log('token expired', keycloak.token);
    // TODO: 3 is a magic number
    keycloak.updateToken(3).success(() => {
        console.log('successfully get a new token', keycloak.token);
    }).error(() => {
        console.log('Could not not update expired token')
    });
}



function App() {
    return (
        <div>
            <ReactKeycloakProvider
                authClient={keycloak}
                initConfig={keycloakProviderInitConfig}
                onEvent={onKeycloakEvent}
                onTokens={onKeycloakTokens}
            >
                <div>
                    <BrowserRouter>
                        <Routes>
                            <Route exact path="/" element={<Home/>}/>
                        </Routes>
                    </BrowserRouter>
                </div>
            </ReactKeycloakProvider>
        </div>
    );
}

export default App;



